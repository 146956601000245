<template>
	<AddEditForm :headerTitle="$t('pages.supplier.editSupplier')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="edit-supplier" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingSupplier != null">
				<template v-slot:body>
					<CForm>
						<CInput :placeholder="$t('pages.supplier.enterSupplierName')" v-model="editingSupplier.name"
								:is-valid="validator"
								horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('common.name') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>
						<CInput :label="$t('pages.supplier.certificate')" :placeholder="$t('pages.supplier.enterCertificate')" v-model="editingSupplier.certificate"
								horizontal>
						</CInput>
						<CInput :label="$t('common.email')" :placeholder="$t('common.enterEmail')" v-model="editingSupplier.email"
								required horizontal>
							<template #invalid-feedback>
								<div class="invalid-feedback">
									{{ $t('common.emailInvalid') }}
								</div>
							</template>
						</CInput>
						<CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')" v-model="editingSupplier.phone"
								horizontal>
						</CInput>

						<CInput :label="$t('common.address')" :placeholder="$t('pages.supplier.enterAddress')" v-model="editingSupplier.address"
								horizontal>
						</CInput>

						<CInput :label="$t('common.description')" :placeholder="$t('pages.supplier.enterDescription')" v-model="editingSupplier.description"
								horizontal>
						</CInput>

						<div v-if="invalidInput" role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label"></label>
							<div class="col-sm-9 input-group">
								<div class="requiredInput">{{ errorInput }}</div>
							</div>
						</div>						
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>
	import { mapGetters } from 'vuex'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'

    export default {
        name: 'supplieredit',
        data() {
			return {
				horizontal: { label:'col-3', input:'col-9' },				
			}
		},
        components: {
            Accordion,
            AddEditForm
        },
        computed: {
            ...mapGetters('supplier', ['allSuppliers', 'editingSupplier', 'hasError', 'errorMessage'])
        },
        methods: {             
            async onUpdateSupplier() {
				
				if(this.editingSupplier.name == '')
				{					
                    this.$store.dispatch('alert/error', this.$t('pages.supplier.enterSupplierName'));
					return;
				}

				if(this.editingSupplier.email != '' && !this.validatorEmail(this.editingSupplier.email))
				{					
                    this.$store.dispatch('alert/error', this.$t('common.emailInvalid'));
					return;
				}
				var retVal = await this.$store.dispatch("supplier/saveSupplier");
				if (retVal == true) {
                    this.onGoBack();
                }
			},
            onSave() {
                this.onUpdateSupplier();
            },
            onGoBack() {
                this.$router.push('/suppliers/list');
			},			
			validator(val) {
                return val ? val.length > 0 : false
            },
			validatorEmail(email) {	
				
				if(email == "") return false;
				let re = /(.+)@(.+){2,}\.(.+){2,}/;

                return re.test(email.toLowerCase()) ? true :  false;
            },						
        },
        mounted() {
            this.$store.dispatch("supplier/editSupplier", this.$route.params.id);
        }
    }
</script>